import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { GLOBAL_SETTINGS_ROUTE } from 'src/app/roles/_shared-by-role/project-dashboard/project.constants';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent implements OnInit {
  isUserDAEngineer: boolean = false;
  isUserChapterManager: boolean = false;
  isUserSME: boolean = false;
  isUserProjectContact: boolean = false;
  readonly GLOBAL_SETTINGS_ROUTE = GLOBAL_SETTINGS_ROUTE;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.isUserDAEngineer = this.userService.isDAEngineer();
    this.isUserChapterManager = this.userService.isChapterManager();
    this.isUserSME = this.userService.isSME();
    this.isUserProjectContact = this.userService.isProjectContact();
  }
}
