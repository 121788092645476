import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatRadioModule,
  MAT_RADIO_DEFAULT_OPTIONS,
} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';
import { ActionDialogComponent } from './components/action-dialog/action-dialog.component';
import { AgGridButtonRenderer } from './components/ag-grid-button-renderer/ag-grid-button-renderer.component';
import { AgGridCheckboxRendererComponent } from './components/ag-grid-checkbox-renderer/ag-grid-checkbox-renderer.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { EditValueDialog } from './components/dialog-with-form/edit-value-dialog.component';
import { EntityCardComponent } from './components/entity-card/entity-card.component';
import { FullscreenToggleComponent } from './components/fullscreen-toggle/fullscreen-toggle.component';
import { InputContainerComponent } from './components/input-container/input-container.component';
import { InputFilterComponent } from './components/input-filter/input-filter.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { NavigationLinkComponent } from './components/navigation-link/navigation-link.component';
import { NavigationTabStripComponent } from './components/navigation-tab-strip/navigation-tab-strip.component';
import { NavigationTreeComponent } from './components/navigation-tree/navigation-tree.component';
import { NotFoundOrNotAuthorizedComponent } from './components/not-found-or-not-authorized/not-found-or-not-authorized.component';
import { NumericCellEditorComponent } from './components/numeric-cell-editor/numeric-cell-editor.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ValueEditWithDialogComponent } from './components/value-edit-with-dialog/value-edit-with-dialog.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { CustomErrorStateMatcherDirective } from './directives/error-state-matcher';
import { FullscreenWrapperDirective } from './directives/fullscreen-wrapper.directive';
import { InputValidationContainerDirective } from './directives/input-validation-container.directive';
import { NgrxMatSelectViewAdapter } from './directives/mat-select-view-adapter';
import { UserSearchAdapterDirective } from './directives/user-search-adapter.directive';
import {
  API_BASE_URL,
  ApplicationVersionClient,
  UserDataClient,
} from './models/autogenerated';
import { AdapCurrencyPipe } from './pipes/adap-currency.pipe';
import { AdapShortDatePipe } from './pipes/adap-short-date.pipe';
import { FormErrorsAsIterable } from './pipes/form-errors-iterable.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { ProjectOperatedStringPipe } from './pipes/project-operated-string.pipe';
import { ProjectTypeStringPipe } from './pipes/project-type-string.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { UserOidToNamePipe } from './pipes/user-oid-to-name.pipe';
import { UserPhotoSanitizerPipe } from './pipes/user-photo-sanitizer.pipe';
import { IRequestCacheService } from './services/requestCache.service';
import { RequestInMemoryCacheService } from './services/requestInMemoryCache.service';
import {
  ITableEventsService,
  TableEventsService,
} from './services/table-events.service';
import { ActionDialogEffects } from './store/action-dialog/action-dialog.effects';
import {
  applicationVersionReducer,
  authReducer,
  cacheClearConfigReducer,
  loadReducer,
} from './store/shared.reducer';
import { SharedEffects } from './store/shared.effects';
import { TimeSincePipe } from './pipes/time-since.pipe';
import { NgrxFormsModule } from 'ngrx-forms';
import { HorizontalItemsCarouselComponent } from './components/horizontal-items-carousel/horizontal-items-carousel.component';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { InputTagsFilterComponent } from './components/input-tags-filter/input-tags-filter.component';
import { AgGridIconButtonRenderer } from './components/ag-grid-icon-button-renderer/ag-grid-icon-button-renderer.component';
import { TextAreaWithCopy } from './components/text-area-with-copy/text-area-with-copy.component';
import { OptionsMenuComponent } from './components/options-menu/options-menu.component';
import { NicknameComponent } from './components/nickname/nickname.component';
import { UserRoleDictionaryPipe } from './pipes/user-role-dictionary.pipe';
import { CountBadge } from './components/count-badge/count-badge.component';
import { SeparatorWithTextComponent } from './components/separator-with-text/separator-with-text.component';
import { ZeroDashPipe } from './pipes/zero-dash-pipe';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AgGridChipRendererComponent } from './components/ag-grid-chip-renderer/ag-grid-chip-renderer.component';
import { MultiUsersSearchComponent } from './components/multi-users-search/multi-users-search.component';
import { MultiUsersSearchAdapterDirective } from './directives/multi-users-search-adapter.directive';
import { OriginChipComponent } from './components/origin-chip/origin-chip.component';
import { FeedbackSnackBarComponent } from './components/feedback-snack-bar/feedback-snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AgGridLinkRendererComponent } from './components/ag-grid-link-renderer/ag-grid-link-renderer.component';
import { AgGridDisciplineChipRendererComponent } from './components/ag-grid-chip-discipline-renderer/ag-grid-chip-discipline-renderer.component';
import { ProjectDeliverableCarryoverLabelComponent } from './components/project-deliverable-carryover-label/project-deliverable-carryover-label.component';
import { ProjectDeliverableCarryoverRendererComponent } from './components/project-deliverable-carryover-renderer/project-deliverable-carryover-renderer.component';
import { InputAutocompleteComponent } from './components/input-autocomplete/input-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MandatoryCheckChipComponent } from './components/mandatory-check-chip/mandatory-check-chip.component';
import { MandatoryCheckChipRendererComponent } from './components/mandatory-check-chip-renderer/mandatory-check-chip-renderer.component';
import { SMEDisciplineChipTooltipPipe } from './pipes/sme-discipline-chip-tooltip.pipe';
import { NgrxQuillValueAccessorDirective } from './directives/quill-editor-accessor-directive';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatIconModule,
    MatBadgeModule,
    MatDialogModule,
    MatTooltipModule,
    MatListModule,
    MatAutocompleteModule,
    FormsModule,
    MatButtonToggleModule,
    MatRadioModule,
    AgGridModule,
    MatSelectModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCheckboxModule,
    NgrxFormsModule,
    MatChipsModule,
    MatSnackBarModule,
    StoreModule.forFeature('load', loadReducer),
    StoreModule.forFeature('auth', authReducer),
    StoreModule.forFeature('appVer', applicationVersionReducer),
    StoreModule.forFeature('cacheClear', cacheClearConfigReducer),
    EffectsModule.forFeature([ActionDialogEffects]),
    EffectsModule.forFeature([SharedEffects]),
    QuillModule.forRoot(),
  ],
  declarations: [
    SeparatorComponent,
    DialogHeaderComponent,
    NavigationTabStripComponent,
    NumericCellEditorComponent,
    NgrxMatSelectViewAdapter,
    UserSearchAdapterDirective,
    MultiUsersSearchAdapterDirective,
    CustomErrorStateMatcherDirective,
    UserSearchComponent,
    UserOidToNamePipe,
    UserPhotoSanitizerPipe,
    ProjectTypeStringPipe,
    ProjectOperatedStringPipe,
    AdapShortDatePipe,
    PluralPipe,
    SpinnerComponent,
    ActionDialogComponent,
    NotFoundOrNotAuthorizedComponent,
    ClickStopPropagationDirective,
    InputValidationContainerDirective,
    PlaceholderComponent,
    InputLabelComponent,
    NavigationTreeComponent,
    NavigationLinkComponent,
    EntityCardComponent,
    InputAutocompleteComponent,
    InputContainerComponent,
    AgGridCheckboxRendererComponent,
    AgGridIconButtonRenderer,
    InputFilterComponent,
    SafeUrlPipe,
    SlideToggleComponent,
    AgGridButtonRenderer,
    FilterMenuComponent,
    FullscreenToggleComponent,
    FullscreenWrapperDirective,
    FormErrorsAsIterable,
    AdapCurrencyPipe,
    EditValueDialog,
    ValueEditWithDialogComponent,
    TimeSincePipe,
    TextAreaWithCopy,
    HorizontalItemsCarouselComponent,
    InputTagsFilterComponent,
    MultiUsersSearchComponent,
    OptionsMenuComponent,
    NicknameComponent,
    UserRoleDictionaryPipe,
    ZeroDashPipe,
    CountBadge,
    SeparatorWithTextComponent,
    AgGridChipRendererComponent,
    AgGridDisciplineChipRendererComponent,
    OriginChipComponent,
    FeedbackSnackBarComponent,
    AgGridLinkRendererComponent,
    ProjectDeliverableCarryoverLabelComponent,
    ProjectDeliverableCarryoverRendererComponent,
    MandatoryCheckChipComponent,
    MandatoryCheckChipRendererComponent,
    SMEDisciplineChipTooltipPipe,
    NgrxQuillValueAccessorDirective,
    RichTextEditorComponent,
  ],
  exports: [
    FormsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    UserSearchComponent,
    SeparatorComponent,
    DialogHeaderComponent,
    NavigationTabStripComponent,
    NgrxMatSelectViewAdapter,
    UserSearchAdapterDirective,
    MultiUsersSearchAdapterDirective,
    NumericCellEditorComponent,
    CustomErrorStateMatcherDirective,
    UserOidToNamePipe,
    UserPhotoSanitizerPipe,
    ProjectTypeStringPipe,
    ProjectOperatedStringPipe,
    AdapShortDatePipe,
    PluralPipe,
    SpinnerComponent,
    ActionDialogComponent,
    NotFoundOrNotAuthorizedComponent,
    ClickStopPropagationDirective,
    InputValidationContainerDirective,
    PlaceholderComponent,
    InputLabelComponent,
    NavigationTreeComponent,
    EntityCardComponent,
    InputAutocompleteComponent,
    InputContainerComponent,
    MatMenuModule,
    MatBadgeModule,
    InputFilterComponent,
    SafeUrlPipe,
    SlideToggleComponent,
    AgGridButtonRenderer,
    AgGridIconButtonRenderer,
    FilterMenuComponent,
    FullscreenToggleComponent,
    FullscreenWrapperDirective,
    FormErrorsAsIterable,
    EditValueDialog,
    ValueEditWithDialogComponent,
    AdapCurrencyPipe,
    TimeSincePipe,
    TextAreaWithCopy,
    HorizontalItemsCarouselComponent,
    MatListModule,
    MatChipsModule,
    MatAutocompleteModule,
    InputTagsFilterComponent,
    MultiUsersSearchComponent,
    OptionsMenuComponent,
    NicknameComponent,
    UserRoleDictionaryPipe,
    ZeroDashPipe,
    CountBadge,
    SeparatorWithTextComponent,
    AgGridChipRendererComponent,
    OriginChipComponent,
    ProjectDeliverableCarryoverLabelComponent,
    ProjectDeliverableCarryoverRendererComponent,
    MandatoryCheckChipComponent,
    MandatoryCheckChipRendererComponent,
    SMEDisciplineChipTooltipPipe,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    NgrxFormsModule,
    NgrxQuillValueAccessorDirective,
    RichTextEditorComponent,
  ],
  providers: [
    { provide: API_BASE_URL, useValue: environment.apiEndpoint },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    { provide: ITableEventsService, useClass: TableEventsService },
    { provide: IRequestCacheService, useClass: RequestInMemoryCacheService },
    ApplicationVersionClient,
    UserDataClient,
    PluralPipe,
  ],
})
export class SharedModule {}
