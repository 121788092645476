import { EntityCardOptionSelect } from 'src/app/shared/models/entity-card-option-select.model';

export const DA_ENGINEER_ROUTE: string = 'da-engineer';
export const CHAPTER_MANAGER_ROUTE: string = 'chapter-manager';
export const SME_ROUTE: string = 'sme';
export const PROJECT_CONTACT_ROUTE: string = 'project-contact';

export const MY_PROJECTS_ROUTE: string = 'my-projects';
export const ALL_PROJECTS_ROUTE: string = 'all-projects';

export const GLOBAL_SETTINGS_ROUTE: string = 'global-settings';

export enum AccessOptions {
  DA_ENGINEERS = 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Members/groupId/73db0311-d7a3-4bc2-a634-e5a864d524c2',
  CHAPTER_MANAGERS = 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Members/groupId/b0cf0fd9-8969-4bc0-a0d0-e5a20988652b',
  SMES = 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Members/groupId/b0fda1db-06ce-467a-8148-f8e99d0b91c0',
  PROJECT_CONTACTS = 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Members/groupId/64d4fb24-63fc-450b-a07f-035595ddb2f4',
  REQUEST_ACCESS = 'https://chevron.service-now.com/mychevron?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981',
}

export const AccessOptionsArray: EntityCardOptionSelect[] = Object.keys(
  AccessOptions
).map((key) => ({
  text: key.replace(/_/g, ' ').toLowerCase(),
  onClick: () => {
    const url = AccessOptions[key as keyof typeof AccessOptions];
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  },
}));

export const VALUE_RECOGNITION_NOTES_MAX_LENGTH = 10000;
